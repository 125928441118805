import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";

const initialState = {
  name: "",
  email: "",
  message: "",
  success: false,
};

export const Contact = (props) => {
  const [{ name, email, message, success }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState(initialState);

  const handleSubmit = (e) => {
  e.preventDefault();
  console.log(name, email, message);
  emailjs
    .sendForm(
      "service_ov7i5rp",
      "template_f21ymsy",
      e.target,
      "vI2NVpk1RkGGJZQNb"
    )
    .then(
      (result) => {
        console.log(result.text);
        setState((prevState) => ({ ...prevState, success: true }));
      },
      (error) => {
        console.log(error.text);
      }
    );
  clearState(); // Call clearState to reset the form fields after submission
};


  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" noValidate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        value={name}
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        value={email}
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    required
                    value={message}
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success">
                  {success && (
                    <p className="text-success">Thanks for Submitting the form</p>
                  )}
                </div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
<h3>Contact Info</h3>
<p>
<span>
<i className="fa fa-map-marker"></i> Address
</span>
{props.data ? props.data.address : "loading"}
</p>
<p>
<span>
<i className="fa fa-phone"></i> Phone
</span>{" "}
{props.data ? props.data.phone : "loading"}
</p>
<p>
<span>
<i className="fa fa-envelope-o"></i> Email
</span>{" "}
{props.data ? props.data.email : "loading"}
</p>
<p>
<span>
<i className="fa fa-globe"></i> Website
</span>{" "}
{props.data ? props.data.website : "loading"}
</p>
</div>
</div>
</div>
</div>
</div>
);
};

export default Contact;
