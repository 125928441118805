import React from "react";

const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
      <button type="button" class="navbar-toggle collapsed pull-right" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a class="navbar-brand page-scroll" href="#page-top">
      <img src="img/logo.png" alt="Logo" class="logo-img"/>
    </a>

  <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
    <ul class="nav navbar-nav navbar-right">
      <li><a href="#features" class="page-scroll">Features</a></li>
      <li><a href="#about" class="page-scroll">About</a></li>
      <li><a href="#services" class="page-scroll">Services</a></li>
      <li><a href="#portfolio" class="page-scroll">Gallery</a></li>
      <li><a href="#testimonials" class="page-scroll">Testimonials</a></li>
      <li><a href="#team" class="page-scroll">Team</a></li>
      <li><a href="#contact" class="page-scroll">Contact</a></li>
    </ul>
    </div>
    </div>
    </nav>
  );
};

export default Navigation;
